
// packages
import React from 'react';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

// styles
import './index.css';

// components
import Site from "./components/Site";
import AboutPage from "./components/About/AboutPage";
import ProjectsPage from "./components/Projects/ProjectsPage";
import NotebooksPage from "./components/Notebooks/NotebooksPage";
import ContactPage from "./components/Contact/ContactPage";
import AllProjects from "./components/Projects/AllProjects";
import AllNotebooks from "./components/Notebooks/AllNotebooks";
import Notebook from "./components/Notebooks/Notebook";
import PersonalSiteProject from "./components/Projects/Projects/PersonalSiteProject";
import MenuAppProject from "./components/Projects/Projects/MenuAppProject";


const TRACKING_ID = "UA-242345977-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Router>
            <Routes>
                <Route path='/' element={<Site />} >
                    <Route index element={<AboutPage />} />
                    <Route path='about' element={<AboutPage />} />
                    <Route path='projects' element={<ProjectsPage />} >
                        <Route index element={<AllProjects />} />
                        <Route path='PersonalSite' element={<PersonalSiteProject />} />
                        <Route path='MenuApp' element={<MenuAppProject />} />
                    </Route>
                    <Route path='notebooks' element={<NotebooksPage />} >
                        <Route index element={<AllNotebooks />} />
                        <Route path=':notebookID' element={<Notebook />} />
                    </Route>
                    <Route path='contact' element={<ContactPage />}/>
                </Route>
            </Routes>
        </Router>
    </React.StrictMode>
);


// TODO: fix notebook dates
// TODO: upload fantasy notebook to github, fix source link
// TODO: wait to load images?
// TODO: adjust picture in star?
// TODO: change units to rem
// TODO: add in moving stars to landing
// TODO: spin title star on click
// TODO: scroll to leave landing

